var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mb-3"},[_c('h3',{staticClass:"v-heading text-h6 hide-in-built-in-mode"},[_vm._v(_vm._s(_vm.$t('crm.view.activities.title')))])]),_c('v-col',{staticClass:"mb-3",attrs:{"cols":"auto"}},[(_vm.activeClient && _vm.userContactInfo && _vm.processId)?_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":_vm.onAddEvent}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$t('global.add'))+" ")],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('AgGridWrapper',{staticClass:"elevation-2 rounded",attrs:{"loading":_vm.gridSettings.loading,"default-sort":_vm.gridSettings.defaultSort},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var themeClass = ref.themeClass;
var gridOptions = ref.gridOptions;
return [_c('ag-grid-vue',{class:themeClass,attrs:{"grid-options":gridOptions,"column-defs":_vm.gridSettings.columnDefs,"row-data":_vm.items},on:{"cell-clicked":_vm.onCellClick}})]}}]),model:{value:(_vm.grid),callback:function ($$v) {_vm.grid=$$v},expression:"grid"}})],1)],1),_c('ContentDialog',{attrs:{"title":_vm.eventFormDialogTitle,"max-width":"800px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var parent = ref.parent;
return [_c('CrmActivityCalendarEventForm',{attrs:{"default-client":_vm.activeClient,"default-client-type":_vm.clientType,"user-contact-info":_vm.userContactInfo,"id":_vm.dialogConfig.eventForm.id,"start-date":_vm.dialogConfig.eventForm.startDate,"end-date":_vm.dialogConfig.eventForm.endDate,"process":_vm.dialogConfig.eventForm.process},on:{"cancel":parent.close,"complete":function($event){[_vm.onAfterSaveEvent($event), $event.flagEnviarEmail ? '' : parent.close()]}}})]}}]),model:{value:(_vm.dialogConfig.eventForm.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.eventForm, "show", $$v)},expression:"dialogConfig.eventForm.show"}}),_c('ContentDialog',{attrs:{"title":_vm.sendEventEmailDialogTitle,"max-width":"800px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var parent = ref.parent;
return [_c('CrmActivityCalendarEventEmailView',{attrs:{"client":_vm.dialogConfig.sendEventEmail.client,"event":_vm.dialogConfig.sendEventEmail.event,"eventModel":_vm.eventModel,"user-contact-info":_vm.userContactInfo,"flagEnviarEmail":_vm.flagEnviarEmail},on:{"cancel":function($event){[_vm.onAfterSendEmail(false), parent.close()]},"complete":function($event){[_vm.onAfterSendEmail(true), parent.close()]}}})]}}]),model:{value:(_vm.dialogConfig.sendEventEmail.show),callback:function ($$v) {_vm.$set(_vm.dialogConfig.sendEventEmail, "show", $$v)},expression:"dialogConfig.sendEventEmail.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
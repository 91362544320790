









































































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { GridReadyEvent, ValueFormatterParams } from '@ag-grid-community/core';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import ContentDialog from '@/components/content-dialog.vue';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import ActivityService from '@/services/crm/activity.service';
import dayjs from '@/plugins/dayjs';
import ActivityEventModel from '@/models/crm/activity-event.model';
import { GridHelper } from '@/utils/helpers/grid-helper';
import { DateHelper } from '@/utils/helpers/date-helper';
import RouterService from '@/services/router.service';
import ClientService from '@/services/crm/client.service';
import ProspectService from '@/services/crm/prospect.service';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import CrmActivityCalendarEventForm from '../../components/crm/activity-calendar-event-form.vue';
import CrmActivityCalendarEventEmailView from '@/components/crm/activity-calendar-event-email-view.vue';
import ProcessModel from '@/models/crm/process.model';
import { IGridCellEvent } from '@/interfaces/grid-cell-clicked.interface';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({
  components: {
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
    CrmActivityCalendarEventForm,
    CrmActivityCalendarEventEmailView,
  },
})
export default class CrmActivities extends Vue {
  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  @InjectReactive('clientType') readonly clientType!: ClientTypeEnum;

  @InjectReactive('userContactInfo') readonly userContactInfo!: UserContactInfo;

  processId: number | null = null;

  clienteId: string | null = null;

  dialogConfig: IKeyValue<IDialogConfig> = {
    confirmation: {
      message: '',
      color: '',
      show: false,
      onChoice: () => {},
    },
    eventForm: {
      id: null,
      show: false,
      startDate: dayjs().toDate(),
      endDate: dayjs().toDate(),
      process: null,
    },
    sendEventEmail: {
      show: false,
      event: null,
      client: null,
    },
  };

  flagEnviarEmail = false;

  eventModel!: ActivityEventModel | null;

  client!: ClientModel;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'dataHoraInicio', sort: 'desc' }],
    columnDefs: [
      {
        headerName: `${this.$t('crm.view.activities.grid.schedule')}`,
        field: 'titulo',
        minWidth: 130,
      },
      {
        headerName: `${this.$t('crm.view.activities.grid.dateAndHour')}`,
        field: 'dataHoraInicio',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params: ValueFormatterParams): string => {
          const format = params.data.timed ? 'dateTime' : 'short';
          return `${this.$d(params.data.dataHoraInicio, format)}`;
        },
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.activities.grid.attendant')}`,
        field: 'atendente.nome',
        minWidth: 130,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.activities.grid.historyType')}`,
        field: 'tipoHistorico.nome',
        minWidth: 130,
      },
    ],
  };

  items: ActivityEventModel[] = [];

  private readonly startAt = '2010-01-01';

  private readonly endAt = dayjs().add(365, 'day').format('YYYY-MM-DD');

  async mounted(): Promise<void> {
    this.processId = this.getProcessIdFromRoute();
    this.clienteId = this.getClientIdFromRoute();

    if (!this.processId) {
      this.$notify.error(this.$t('crm.view.activities.message.emptyProcessId'));
      return;
    }

    const process = new ProcessModel();
    process.id = this.processId;
    this.dialogConfig.eventForm.process = process;

    this.loadItems();
  }

  async onCellClick(event: IGridCellEvent<ActivityEventModel>): Promise<void> {
    if (!event.data) {
      return;
    }

    this.dialogConfig.eventForm.id = event.data.id;
    this.dialogConfig.eventForm.show = true;
  }

  onAddEvent(): void {
    this.dialogConfig.eventForm.id = null;
    this.dialogConfig.eventForm.show = true;
  }

  async onAfterSaveEvent(result: ActivityEventModel): Promise<void> {
    await this.loadItems();

    if (result.flagEnviarEmail) {
      this.flagEnviarEmail = true;
      this.eventModel = result;
      if (result) {
        this.onSendEmail(result);
      }
    }
  }

  onAfterSendEmail(sent: boolean): void {
    this.flagEnviarEmail = false;
    this.dialogConfig.sendEventEmail.show = false;
    if (sent) this.dialogConfig.eventForm.show = false;
  }

  async onSendEmail(event: ActivityEventModel): Promise<void> {
    if (!event.id) {
      return;
    }

    this.eventModel = await this.activityService.getEvent(event.id);
    const loader = this.$loading.show();
    try {
      await this.loadClient(event);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }

    this.dialogConfig.sendEventEmail.client = this.client;
    this.dialogConfig.sendEventEmail.event = event;
    this.dialogConfig.sendEventEmail.show = true;
  }

  private async loadClient(event: ActivityEventModel): Promise<void> {
    if (event.tipo === ClientTypeEnum.Prospect) {
      this.client = (await this.prospectService.get(event.prospect?.uuid as unknown as number))?.clientModel;
    }
    this.client = (await this.clientService.get((event.cnpj || '').replace(/\D/g, ''), ClientTypeEnum.Client));
  }

  get eventFormDialogTitle(): string {
    let titleKey = 'crm.activityCalendar.dialog.calendarEventForm.newTitle';

    if (this.dialogConfig.eventForm.id) {
      titleKey = 'crm.activityCalendar.dialog.calendarEventForm.editTitle';
    }

    return `${this.$t(titleKey)}`;
  }

  get sendEventEmailDialogTitle(): string {
    const event = this.dialogConfig.sendEventEmail.event as ActivityEventModel;
    const title = event && event.nome;
    return `${this.$t('crm.activityCalendar.dialog.activityCalendarEventView.title', { title })}`;
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;
    this.items = await this.activityService.getEventsByProcess(this.processId as number);
    this.gridSettings.loading = false;
  }

  private getClientIdFromRoute(): string {
    if (this.clientType === ClientTypeEnum.Prospect) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }

  private getProcessIdFromRoute(): number {
    const currentRoute = this.routerService.route();
    return (currentRoute.query && currentRoute.query.processId) as unknown as number;
  }
}
